<template>
  <div class="messagebox" >
    <div class="messagebox-header">
      <!-- <div>{{type}}</div> -->
      <div>
   
        <!-- {{activedKey[type].name}} -->
        <!-- <span v-if="activedKey[type].friendDetail">{{
          activedKey[type].friendDetail.nickname
            ? activedKey[type].friendDetail.nickname
            : activedKey[type].name
        }}</span> -->
      <span class="nicknamer">
          {{keyname.nicknamer?keyname.nicknamer:"未设置昵称"}}
      </span>
   
       
      </div>
    </div>

    <div class="messagebox-content" ref="msgContent">
      <div class="moreMsgs" @click="loadMoreMsgs">{{ loadText }}</div>
      <div
        v-for="(item, i) in msgList"
        :key="i"
        class="message-group"
        :style="{ float: item.bySelf ? 'right' : 'left' }"
      >
       
        <!-- 撤回消息 -->
        <div v-if="item.status == 'recall'" class="recallMsg">
          {{ item.msg }}
        </div>
        <div v-if="item.status == 'recall'" class="recallMsg">
          {{ renderTime(item.time) }}
        </div>
        <!-- 撤回消息 end -->
        <a-dropdown
          v-else
          :trigger="['contextmenu']"
          :style="{ float: item.bySelf ? 'right' : 'left' }"
          :disabled="!item.bySelf"
        >
          <span style="user-select: none">
            <!-- <el-dropdown v-else @command="handleCommand(item)" trigger="click" :style="{'float':item.bySelf ? 'right':'left'}">
            <span class="el-dropdown-link">-->
            <!-- 图片消息 -->
            <img
              :key="item.msg"
              :src="item.msg ? item.msg : ''"
              v-if="item.type === 'img'"
              class="img-style"
            />
            <!-- 文件card -->
            <div
              v-else-if="item.type === 'file'"
              class="file-style"
              :style="{ float: item.bySelf ? 'right' : 'left' }"
            >
              <el-card :body-style="{ padding: '0px' }">
                <div style="padding: 14px">
                  <h2>文件</h2>
                  <span>
                    <h3>{{ item.filename }}</h3>
                  </span>
                  <div class="bottom clearfix">
                    <span>{{ readablizeBytes(item.file_length) }}</span>
                    <a :href="item.msg" :download="item.filename">点击下载</a>
                  </div>
                </div>
              </el-card>
            </div>
            <!-- 音频消息 -->
            
            <!-- 视频消息 -->
           
            <!-- 聊天消息 -->
            <p
              style="user-select: text"
              v-else
              v-html="renderTxt(item.msg)"
              :class="{ byself: item.bySelf }"
            />

            <!-- <div v-if="item.bySelf?true:false" class="status">{{status[item.status]}}</div> -->
          </span>
          <!-- <el-dropdown-menu slot="dropdown" >
            <el-dropdown-item command="a" :disabled="!item.bySelf">撤回</el-dropdown-item>
          </el-dropdown-menu>
          </el-dropdown>-->

          <a-menu slot="overlay">
            <a-menu-item key="1" @click="handleCommand(item)"></a-menu-item>
          </a-menu>
        </a-dropdown>

        <!-- 聊天时间 -->
        <div
          v-if="item.status !== 'recall'"
          class="time-style"
          :style="{ 'text-align': item.bySelf ? 'right' : 'left' }"
          
        >
          {{ renderTime(item.time) }}
          <!-- {{ item.bySelf ? status[item.status] : "" }} -->
        </div>
      </div>
    </div>
    <div class="messagebox-footer">
      <div class="footer-icon">
        <!-- 表情组件 -->
        <!-- <ChatEmoji v-on:selectEmoji="selectEmoji" :inpMessage="message" /> -->
        <!-- 上传图片组件 -->
        <UpLoadImage :type="this.type" :chatId="activedKey[type]" />
        <!-- 上传文件组件 -->
        <UpLoadFile :type="this.type" :chatId="activedKey[type]" />
        <!-- 发送语音 -->
       
      </div>
      <div class="fotter-send">
        <a-input
          v-model="message"
          equired
          placeholder="请在此输入消息"
          class="sengTxt"
          @pressEnter="onSendTextMsg"
          style="resize: none"
          ref="txtDom"
        />
         <el-button type="danger" @click="onSendTextMsg">发送</el-button>
        <template />
      </div>
    </div>
   
  </div>
</template>
<script>

import UpLoadImage from "../upLoadImage/index.vue";
import UpLoadFile from "../upLoadFile/index.vue";
import "./index.scss";
import { mapActions, mapGetters } from "vuex";
import ChatEmoji from "../chatEmoji/index.vue";
import emoji from "../../config/emoji";
import moment from "moment";
import _ from "lodash";
export default {
  data() {
    return {
      activedKey: {
        contact: "",
      
      },
      keyname:"",
      message: "",
      isload:false,
      loadText: "加载更多",
      status: {
        sending: "发送中",
        sent: "已发送",
        read: "已读",
      },
      nowIsVideo: false,
    };
  },

  beforeMount() {
     setTimeout(() => {
        this.onGetContactUserList();  
      }, 100);
  },
  updated() {
   
    this.scollBottom();
  },
  computed: {
    ...mapGetters({
      contact: "onGetContactUserList",
      chatroom: "onGetChatroomUserList",
      msgList: "onGetCurrentChatObjMsg",
    }),
    //控制聊天框
     //控制聊天框
    toggleWindows(){
      let show
      switch(this.type){
        case 'contact':
          show = this.activedKey[this.type]!=''?true:false
          break
      }
      return show
    },
    userList() {
      return {
        contact: this.contact,
      };
    },
    selectedKeys() {
      
      return [this.getKey(this.activedKey[this.type]) || ""];
    },
  },
  props: [
    "type", // 聊天类型 contact, group, chatroom
    "username", // 选中的聊天对象
    "broken", // 是否适应移动端
    "showUserList",
    "hideUserList",
  ],
  methods: {
    ...mapActions([
      "onGetContactUserList",
      "onGetCurrentChatObjMsg",
      "onSendText",
      "onCallVideo",
      "onCallVoice",
      "getGroupMembers",
      "getHistoryMessage",
      "onAddBlack",
      "onDelteFirend",
      
      "recallMessage",
      "onGetGroupBlack",
    ]),
    getKey(item, type) {
      let key = "";
      switch (type) {
        case "contact":
          key = item.name;
          break;
      
        default:
          break;
      }
      return key;
    },

    select(key) {
      this.keyname = key
      this.$data.activedKey[this.type] = key;
      const me = this;
      me.$data.loadText = "加载更多";
      // if( me.roomId){
      //     WebIM.conn.quitChatRoom({
      //         roomId: me.roomId // 聊天室id
      //     });
      //     me.roomId = ''
      //   }
        //this.$router.push({ name: this.type, params: { id: key.name } });
        this.onGetCurrentChatObjMsg({ type: this.type, id: key.name });
        setTimeout(() => {
          Vue.$store.commit("updateMessageStatus", {
            action: "oneUserReadMsgs",
            readUser: key.name,
          });
          this.$forceUpdate();
        }, 100);

        if (!this.msgList) {
          this.getHistoryMessage({ name: key.name, isGroup: false });
        }
      } ,
    loadMoreMsgs() {
      const me = this;
      const success = function (msgs) {
        if (msgs.length === 0) {
          me.$data.loadText = "已无更多数据";
        }
      };
      let name = "";
      let isGroup = false;
      if (this.type === "contact") {
        name = this.$data.activedKey[this.type].name;
      }
      this.getHistoryMessage({
        name,
        isGroup,
        success,
      });
    },
    changeMenus() {
      if (this.type === "contact") {
      
      } else if (this.type === "group") {
        this.$refs.groupInfoModel.chengeInfoModel();
      }
    },
    menuClick(i) {
      this.changeMenus();
      switch (i) {
        case "1":
        
          this.onAddBlack({
            userId: this.$data.activedKey[this.type],
          });
          this.$data.activedKey.contact = "";
          this.$router.push({
            // 核心语句
            path: "/contact", // 跳转的路径
          });
          break;
        case "2":
          this.onDelteFirend({
            userId: this.$data.activedKey[this.type],
            callback: () => {
              this.closeContactMessage();
            },
          });
          break;
        default:
          break;
      }
    },

    onSendTextMsg() {
      if (this.$data.message == "" || this.$data.message == "\n") {
        this.$data.message = "";
        return;
      }
      this.onSendText({
        chatType: this.type,
        chatId: this.$data.activedKey[this.type],
        message: this.$data.message,
      });
      this.$data.message = "";
      if(!this.msgList){
        setTimeout(() => {
                this.select(this.keyname)
        }, 500);
      }

    },
    selectEmoji(v) {
      this.$data.message = v;
      this.$refs.txtDom.focus();
    },
    customEmoji(value) {
      return `<img src="../../../static/faces/${value}" style="width:20px"/>`;
    },

    renderTxt(txt = "") {
   
        this.onGetContactUserList();  
      let rnTxt = [];
      let match = null;
      const regex = /(\[.*?\])/g;
      let start = 0;
      let index = 0;
      while ((match = regex.exec(txt))) {
        index = match.index;
        if (index > start) {
          rnTxt.push(txt.substring(start, index));
        }
        if (match[1] in emoji.obj) {
          const v = emoji.obj[match[1]];
          rnTxt.push(this.customEmoji(v));
        } else {
          rnTxt.push(match[1]);
        }
        start = index + match[1].length;
      }
      rnTxt.push(txt.substring(start, txt.length));
      return rnTxt.toString().replace(/,/g, "");
    },
    readablizeBytes(value) {
      let s = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
      let e = Math.floor(Math.log(value) / Math.log(1024));
      return (value / Math.pow(1024, Math.floor(e))).toFixed(2) + " " + s[e];
    },

    // TODO 可以抽离到utils
    renderTime(time) {
      var t = new Date(parseInt(time));
      var Y = t.getFullYear();
      var M =
        t.getMonth() + 1 < 10 ? "0" + (t.getMonth() + 1) : t.getMonth() + 1;
      var D = t.getDate() < 10 ? "0" + t.getDate() : t.getDate();
      var H = t.getHours() < 10 ? "0" + t.getHours() : t.getHours();
      var F = t.getMinutes() < 10 ? "0" + t.getMinutes() : t.getMinutes();
      var S = t.getSeconds() < 10 ? "0" + t.getSeconds() : t.getSeconds();
      return `${M}-${D} ${H}:${F}`;
    },
    // 得到历史列表
    getLastMsg(item) {
      const { name, params } = this.$route;
      const chatList = this.$store.state.chat.msgList[name];
     
      let userId = "";
      if (name == "contact") {
        userId = item.name;
      }
      const currentMsgs = chatList[userId] || [];
      const lastMsg = currentMsgs.length
        ? currentMsgs[currentMsgs.length - 1].msg
        : "";
      const msgTime = currentMsgs.length
        ? this.renderTime(currentMsgs[currentMsgs.length - 1].time)
        : "";
      return { lastMsg, msgTime };
    },
    scollBottom() {
      setTimeout(() => {
        const dom = this.$refs.msgContent;
        if (!dom) return;
        dom.scrollTop = dom.scrollHeight;
      }, 0);
    },
    handleCommand(item) {
      let name = "";
      if (this.type === "contact") {
        name = this.$data.activedKey[this.type].name;
      }
      this.recallMessage({ to: name, message: item });
    },

    // changeIsVideoState(v) {
    //   v ? (this.$data.nowIsVideo = true) : (this.$data.nowIsVideo = false);
    // }
  },
  components: {
    ChatEmoji,
    UpLoadImage,
    UpLoadFile,
    // AddAVMemberModal,
    // MultiAVModal,
    // EmediaModal,
  }
};
</script>

<style scoped lang="scss">
.nicknamer{
  font-size: 16px;
  font-weight: 600;
}
.sengTxt{
  //  border: 1px solid red !important;
   width: 65% !important;
   margin-right: 20px;
   height: 50% !important;
}
.messagebox{
  border-left: 1px solid rgb(240, 240, 240);
  width: 600px;
  height: 500px;
}
.messagebox .messagebox-content{
  // overflow: hidden;
  width: 95%;
  padding: 10px 15px;
}
.byself {
  float: right;
}
.recallMsg {
  font-size: 12px;
  color: #aaa;
  width: 100%;
  text-align: center;
}
.custom-title {
  font-weight: 500;
}
.moreMsgs {
  background: #ccc !important;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}
.status {
  display: inline;
  position: relative;
  top: 20px;
  font-size: 12px;
  left: -6px;
  color: #736c6c;
  float: left;
}
.unreadNum {
  float: left;
  width: 100%;
}
.icon-style {
  display: inline-block;
  background-color: #f04134;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  line-height: 1.5;
  text-align: center;
}
.emoji-style {
  width: 22px;
  float: left;
}
.img-style {
  max-width: 350px;
}
.time-style {
  clear: both;
  margin-left: 2px;
  margin-top: 3px;
  font-size: 12px;
  color: #888c98;
}
.file-style {
  width: 240px;
  margin: 2px 2px 2px 0;
  font-size: 13px;
  h2 {
    border-bottom: 1px solid #e0e0e0;
    font-weight: 300;
    text-align: left;
  }
  h3 {
    max-width: 100%;
    font-size: 15px;
    height: 20px;
    line-height: 20px;
    font-weight: 600;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    margin-bottom: 20px;
  }
  .bottom {
    span {
      color: #999999;
      text-align: left;
    }
  }
  a {
    color: #999999;
    float: right;
    text-decoration: none;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
}
</style>
